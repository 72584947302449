<script setup>
import { defineProps, onBeforeMount, computed } from "vue"
import { useRouter, useRoute } from "vue-router/composables"
import { useSimHistory, BASIC_INFO_COLUMNS } from "@/composition/sim/use-sim-history"
import { JOURNAL_INFO_COLS } from "@/composition/tables/use-table-history"
import UiHistoryView from "@/components/basic/views/UiHistoryView.vue"
import XSelect from "@/components/basic/XSelect.vue";
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import { ITEMS_PER_PAGE_OPTIONS_DEFAULT } from "@/composition/tables/use-table-settings"

const SERVE_HISTORY_FROM_PHP = JSON.parse(process.env.VUE_APP_USE_SIM_HISTORY_PHP)

const props = defineProps({
  id: {
    type: [Number, String],
    required: true
  }
})

const router = useRouter()
const route = useRoute()
const simId = computed(() => {
  if (SERVE_HISTORY_FROM_PHP) {
    return route.query.id
  }
  return props.id
})

const {
  meta,
  init,
  history,
  simName,
  updateParams,
  params,
  lastSimState,
  isLoading,
  visibleSimColumns
} = useSimHistory({ id: simId.value })


const journalInfoCols = computed(() => {
  return JOURNAL_INFO_COLS.map((col) => {
    const newCol = { ...col }
    newCol.key = `history.${col.key}`
    return newCol
  })
})

const goBack = () => {
  if (window.history.length > 2) {
    router.back()
  } else {
    if (SERVE_HISTORY_FROM_PHP) {
      router.push({ query: { f: "administration", f2: "sims" } })
      return
    }
    router.push({ name: "sims" })
  }
}

onBeforeMount(async () => {
  await init()
})
</script>

<template>
  <UiHistoryView
    :is-loading="isLoading"
    :search-text="params.search"
    :history-item-name="simName"
    :basic-info-cols="BASIC_INFO_COLUMNS"
    :basic-info="lastSimState"
    :journal-info-cols="journalInfoCols"
    :object-info-cols="visibleSimColumns"
    :items="history"
    :sort-by="params.sortBy"
    :sort-desc="params.descending"
    @go-back="() => goBack()"
    @update:search-text="(txt) => updateParams({ ...params, search: txt })"
    @update:sortBy="(colKey) => updateParams({ ...params, sortBy: colKey })"
    @update:sortDesc="(desc) => updateParams({ ...params, descending: desc })"
  >
    <template #basic-table-cell(history-createDate)="{ cellValue, col }">
      {{ col.formatter(cellValue) }}
    </template>

    <template #cell(history-createDate)="{ cellValue, col }">
      {{ col.formatter(cellValue) }}
    </template>

    <template #cell(history-modifyDate)="{ cellValue, col }">
      {{ col.formatter(cellValue) }}
    </template>

    <template #cell(tags)="{ cellValue }">
      <div class="sim-view__tags-cell" v-html="cellValue.join(' <wbr> ')" />
    </template>

    <template #cell(user)="{ cellValue }">
      <v-tooltip top>
        <template #activator="{ attrs, on }">
          <span class="sim-view__owner-first-name" v-bind="attrs" v-on="on">
            {{ cellValue.firstName }}
          </span>
        </template>

        <span>
          {{ `${cellValue.firstName} ${cellValue.lastName}` }}
        </span>
      </v-tooltip>
    </template>

    <template #pagination>
      <div
        v-if="meta.page"
        class="sim-history-view__footer"
      >
        <PaginationBlock
          :total-count="meta.totalCount"
          :current-page="meta.page"
          :items-per-page="meta.itemsPerPage"
          @update:current-page="(pageN) => updateParams({ ...params, page: pageN })"
        />

        <XSelect
          class="sim-view__items-per-page-select"
          :items="ITEMS_PER_PAGE_OPTIONS_DEFAULT"
          :value="meta.itemsPerPage"
          @input="(_iPerPage) => updateParams({ ...params, itemsPerPage: _iPerPage})"
          label="Items per page"
          :autocomplete="false"
        />
      </div>
    </template>
  </UiHistoryView>
</template>

<style lang="scss">
:root {
  --v-application-wrap-display: grid;
  --v-application-wrap-overflow: hidden;
  --main-v3-max-height: none;
}

.sim-history-view {
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }

  &__items-per-page-select {
    width: 120px;
    flex-shrink: 0;
  }

  &__tags-cell {
    max-width: 100px;
    min-width: 150px;
  }
}
</style>
